import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Clutch from "../components/clutch"
import Slider from "react-slick"
import CDesign__Service from "../images/creativedesign1.png"
import Process__Step_Sr1 from "../images/creative_design/creative_brief.png"
import Process__Step_Sr2 from "../images/creative_design/innovative.png"
import Process__Step_Sr3 from "../images/creative_design/design.png"
import Logo_branding_Sr from "../images/logobranding1.jpg"
import Logo_branding_Sr1 from "../images/logobranding2.jpg"
import Concept_Sr from "../images/concep.jpg"
import ATG_Sr from "../images/creative_design/ecommerce_design.png"
import PM_Sr from "../images/creative_design/uiux.jpg"
import Wireframe from "../images/creative_design/wireframes.jpg"
import MotionG_Sr from "../images/creative_design/webdesign.jpg"
import Creative_top from "../images/creative_design/creative-top.png"
import Packingfull from "../images/packing_full.png"
import { Helmet } from "react-helmet/es/Helmet";


const creative__picture = {
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
}

export default class Container extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
          <meta name="description"
            content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no" />
          <link rel="canonical" href="https://www.cueblocks.com/team.php" />
          <meta name="description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development." />
          <meta property="og:site_name" content="Cueblocks" />
          <meta property="fb:app_id" content="289086684439915" />
          <meta property="og:url" content="https://www.cueblocks.com/team.php" />
          <meta property="og:title"
            content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing." />
          <meta property="og:description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development." />
          <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development." />
          <meta name="twitter:title"
            content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing." />
          <meta name="twitter:site" content="@cueblocks" />
          <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg" />
        </Helmet>
        <section className="ser_sec_row  creative_design_page creative-top ">
          <div className="container">
            <div className="ser_sec_row_lt wdt-40 pd_right_40">
              <h2 className="heading_main">creative design</h2>
              <div className="ser_sec_row_lt_text">
                <p>
                  Our purpose is to break away from the mundane by imagining and creating original eCommerce designs and experiences. Our designs are not only aesthetically
                   appealing but also lay a big emphasis on conversion. Beginning with an in-depth understanding of your brand, we pick the most befitting design elements by 
                   seamlessly blending our design sensibilities with your brand’s aesthetic to create an alluring identity of your business in the industry.
                </p>
              </div>
     
            </div>
            <div className="ser_sec_row_rt wdt-60 pd_left_40">
              <img
                src={Creative_top}
                alt="Cue Blocks"
                className="img-responsive"
              />
            </div>
           
          </div>
        </section>

        <section className=" our-process">
          <div className="container">
            <div className="main-heading text-center">
              <h2 className="heading_main">our process</h2>
            </div>
            <div className="process-steps">
              <div className="steps">
                <div className="steps-inn">
                  <figure>
                    <img
                      src={Process__Step_Sr1}
                      alt="Creative Brief<"
                      className="image_responsive"
                    />
                  </figure>
                  <h3>The Creative Brief</h3>
                  <p>
                    An extensive initial consultation gives us an insight into your brand’s design language and aesthetic, helping us dive deeper into our research your 
                    business and the industry it operates in.
                  </p>
                </div>
              </div>
              <div className="steps">
                <div className="steps-inn">
                  <figure>
                    <img
                      src={Process__Step_Sr2}
                      alt="Innovative Rendition"
                      className="image_responsive"
                    />
                  </figure>
                  <h3>The Innovative Rendition</h3>
                  <p>
                    With a mood board of ideas, we work on the most suitable colors, fonts, images, content, functionalities, and other design elements that will help us
                     build an intuitive website for your customers.

                  </p>
                </div>
              </div>
              <div className="steps">
                <div className="steps-inn">
                  <figure>
                    <img
                      src={Process__Step_Sr3}
                      alt="Design"
                      className="image_responsive"
                    />
                  </figure>
                  <h3>The Final Design</h3>
                  <p>
                    Keeping your business’s needs in mind, we work with you to give a piece of art that will stand apart from the crowd, increasing your brand’s impact. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="creativedesign_testimonials" id="logobrand">
          <div className="container">
            <div className="main-heading">
              <h2 className="heading_main">Brand Identity</h2>
              <p>
                Setting up a successful business that perfectly represents who you are as a brand can be quite tricky. With over a decade of experience, we focus on
                 locking in the basics of the design structure of your site, from choosing the right colors to creating an expressive yet innovative logo that gives 
                 your brand the identity that it deserves. 
              </p>
            </div>
            <div className="testimonial_row_div">
              <div className="testimonial_row_div_left1">
                <div className="testimonial_slider ">
                  <Slider {...creative__picture}>
                    <div className="testimonial_slider_content">
                      <figure>
                        <img
                          src={Logo_branding_Sr}
                          alt="Vegan Restaurant in Brno, Czech Republic"
                          className="img-responsive"
                        />
                      </figure>
                      <span className="highlight-span">
                        Vegan Restaurant in Brno, Czech Republic
                      </span>
                    </div>
                    <div className="testimonial_slider_content">
                      <figure>
                        <img
                          src={Logo_branding_Sr1}
                          alt="Brand Identity"
                          className="img-responsive"
                        />
                      </figure>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="packages" id="packing">
          <div className="container">
            <div className="main-heading">
              <h2 className="heading_main">Packaging Design</h2>
              <p>
                Much like the identity of your brand, the products you offer also need to be presented in a way that makes them irresistible to your customers.
                 Keeping the “What”, “Who” and “How” of your product in mind, our team of experts creates captivating packaging designs that will have your customers
                  coming back to your brand. 
              </p>
            </div>
            <div className="ser_sec_row_rt wdt-100">
              <img
                src={Packingfull}
                alt="Packaging Design"
                className="img-responsive"
              />
            </div>
          </div>
        </section>

        <section className="products_block conpetulatization" id="concept">
          <div className="container">
            <div className="conpetulatization_upper-block">
              <div className="ser_sec_row_lt  wdt-60">
                <h2 className="heading_main">Illustration</h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    If a picture is a thousand words, an illustration holds twice as much value when viewed through a customer’s lens. Using our adept design sensibilities, 
                    we create powerful visuals that target all the right touchpoints of your audience’s imagination, leaving a lasting imprint in their mind. 

                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt  wdt-40">
                <img
                  src={Concept_Sr}
                  alt="Illustration"
                  className="img-responsive"
                />
              </div>
            </div>
            <div className="all-grows-block" id="crt-degn">
              <div className="ser_sec_row_lt  wdt-40">
                <h2 className="heading_main">
                  eCommerce
                  <br />
                  design
                </h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    An easy-to-access and navigate eStore attracts more eyeballs than a regular eCommerce 
                    website solely for its functionality. Using an 
                    organized system of execution, our team dives deep into understanding the psyche of your brand, thereby creating a user-friendly 
                    eStore for your business that caters to all of its needs.
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt  wdt-60">
                <img src={ATG_Sr} alt="eCommerce design" className="img-responsive" />
              </div>
            </div>
          </div>
        </section>
        
        <section className=" motion-graphics right-img webdesign">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_rt pd_left_40 right">
                <figure>
                  <img
                    src={MotionG_Sr}
                    alt="Web Design"
                    className="img-responsive"
                  />
                </figure>
              </div>
              <div className="ser_sec_row_lt pd_right_40">
                <h2 className="heading_main">Web Design</h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    The way a creator sees a website is quite different from how a user experiences it. To bridge the gap between great design & smooth functionality,
                    our team of design experts creates a user-friendly layout, complete with content and other design elements that can be easily translated into code.
                  </p>
                </div>
                <Link to="/contact" className="btn main_cta">
                  contact us
                  </Link>
              </div>
            </div>
          </div>
        </section>

      

        <section className="motion-graphics uiux" >
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_rt pd_right_40">
                <img src={PM_Sr} alt="UI/UX Development" className="img-responsive" />
              </div>
              <div className="ser_sec_row_lt pd_left_40">
                <h2 className="heading_main">UI/UX Development </h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    Your customers may forget about the products your brand offers, but they will never forget the experience they had on your website.
                     The credit for such a simple, user-friendly, and intuitive website goes to UI/ UX Development. Based on comprehensive research of your brand,
                      we create an enjoyable experience for your customers through a user-friendly interface.
                  </p>
                </div>
                <Link to="/contact" className="btn main_cta">
                  Contact{" "}
                </Link>
              </div>
              
            </div>
          </div>
        </section>

        <section className=" motion-graphics right-img fleex">
          <div className="container">
            <div className="print-outer">
              <div className="ser_sec_row_rt pd_left_40 right">
                <figure>
                  <img
                    src={Wireframe}
                    alt="Web Design"
                    className="img-responsive"
                  />
                </figure>
              </div>
              <div className="ser_sec_row_lt pd_right_40">
                <h2 className="heading_main">Wireframes & Prototyping</h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    To ensure that your website provides your customers with a user-friendly experience, testing is extremely crucial. After thoroughly brainstorming a 
                    bunch of ideas, our team of designers fleshes out the aptest designs that help in building a high-fidelity prototype with functionality, serving as
                     a guide to the creative design and build process. 
                  </p>
                </div>
                <Link to="/contact" className="btn main_cta">
                  contact us
                  </Link>
              </div>
            </div>
          </div>
        </section>

        <Clutch />
      </Layout>
    )
  }
}
